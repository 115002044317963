<template>
  <v-container class="px-0 py-0 invoice-pdf" fluid>
    <v-overlay opacity="0" :value="loading" z-index="999">
      <v-progress-circular
        indeterminate
        color="primary"
        :size="70"
      ></v-progress-circular>
    </v-overlay>
    <!-- report content -->
    <v-container class="py-9 px-10" fluid>
      <v-container class="px-16">
        <v-row class="d-flex justify-center pt-2">
          <span class="text-h6">{{ companyName }}</span>
        </v-row>
        <v-row class="d-flex justify-center font-weight-light pb-5">
          <span>{{ caoContact }}</span>
        </v-row>

        <!-- <v-row class="pt-5"><v-divider /></v-row> -->

        <!-- <v-row class="pt-4 pb-8">
          <v-col class="d-flex justify-center">
            <b style="color: #4caf50">Transaction ID&nbsp;</b>
            <b> {{ order.transactionId }}</b>
          </v-col>
        </v-row> -->

        <div class="py-3 pt-9">
          <v-row v-for="(item, i) in order.items" :key="i">
            <v-col cols="6" class="font-weight-light py-0">
              {{ item.key }}
            </v-col>
            <v-col cols="6" class="d-flex justify-end caption py-0">
              <b>{{ item.val }}</b>
            </v-col>
            <v-col>
              <v-divider v-if="i !== order.items.length - 1" />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import { checkout, HeaderKeys, projects, setHeaders } from "@/api";
import Vue from "vue";
import html2pdf from "html2pdf.js";
import { Actions } from "@/store/models";
import { capitalizeBy_, getDatetime } from "@/utils";
export default Vue.extend({
  name: "ReportPdf",
  components: {},
  data() {
    return {
      loading: true,
      order: {},
    };
  },
  computed: {
    id: function () {
      return Number(this.$route.params.id);
    },
    method: function () {
      return this.$route.query.method;
    },
    companyName: function () {
      return this.$store.state.company.name;
    },
    cao: function () {
      return this.$store.state.company?.account?.cao || {};
    },
    caoContact: function () {
      return `${this.cao.first_name} ${this.cao.last_name} | ${this.cao.user_name}`;
    },
  },
  async created() {
    await this.getOrder();
    setTimeout(() => {
      this.generatePdf();
    }, 1500);
  },
  methods: {
    async getOrder() {
      try {
        const response = (await checkout.getPaymentOrder(this.id)).data[0];
        this.order.transactionId = response.order_id;
        this.order.items = [
          {
            key: "Date Issued",
            val: getDatetime(response.created_datetime),
          },
          {
            key: "Transaction ID",
            val: response.order_id,
          },
          {
            key: "Transaction Type",
            val: capitalizeBy_(response.order_type),
          },
          {
            key: "Payment Method",
            val:
              JSON.parse(
                response.payment_method_info
              ).card?.brand?.toUpperCase() +
                " " +
                JSON.parse(response.payment_method_info)?.card?.last4 || "XXXX",
          },
          {
            key: "Total Amount",
            val: "$" + (response.order_amount / 100).toFixed(2),
          },
        ];
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      } finally {
        this.loading = false;
      }
    },

    async generatePdf() {
      const element = document.getElementById("app");
      const filename = "Invoice_" + this.order.transactionId;
      const opt = {
        margin: 0,
        filename: filename,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          useCORS: true,
          y: 0,
          scale: 4,
        },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };

      try {
        // generate pdf
        const pdfObj = await html2pdf()
          .set(opt)
          .from(element)
          .toPdf()
          .get("pdf");

        if (this.method === "print") {
          window.print();
        } else if (this.method === "download") {
          await pdfObj.save(filename);
          // this.close();
        }
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },

    close() {
      window.close();
    },
  },
});
</script>
<style lang="scss">
.invoice-pdf {
  background: white !important;
  color: black !important;
  height: 100%;
  div,
  h2,
  h5,
  span,
  input,
  label,
  hr,
  i,
  p,
  textarea {
    color: black !important;
    border-color: rgb(135, 131, 131) !important;
  }
}
</style>